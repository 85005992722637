// import React, { createContext, useState, ReactNode, useEffect, useContext } from 'react';

// interface IframeData {
//     agentid:string;
//     customername: string,
//     botCardBackgroundColor: string;
//     botCardTextColor: string;
//     headerLogoColor?:string;
//     chipHoverBackgroundColor: string;
//     chipHoverTextColor: string;
//     chipSelectedColor: string;
//     chipSelectedHoverColor: string;
//     userCardBorderColor: string;
//     infoTextTitleTextColor: string;
//     meetingCardTitleTextColor: string;
//     cardInfoChipsBorderColor: string;
//     cardInfoChipsBackgroundColor: string;
//     cardInfoChipsFontColor:string,
//     cardInfoSelectedChipsBackgroundColor:string,
//     cardInfoSelectedChipsBorderColor:string,
//     cardInfoSelectedChipsFontColor:string,
//     formTextAreaBorderColor: string;
//     formTextAreaBackgroundColor: string;
//     formTextAreaActiveBorderColor: string;
//     formTextAreaActiveBackgroundColor: string;
//     formSubmitBtnBackgroundColor: string;
//     uploadBtnsBackgroundColor: string;
//     uploadBtnsTextColor: string;
//     jobDetailsFormMainBackgroundColor: string;
//     chipSelectedBackgroundColor: string;
//     chipSelectedBorderColor: string;
//     chipSelectedHoverBorderColor: string;
//     enabledBtnBackgroundColor: string;
//     disabledBorderColor: string;
//     disabledTextColor: string;
//     chipSelectedHoverTextColor: string;
//     chipHoverBorderColor: string;
//     formTextAreaBorder2Color: string
//     headerAvatar: string,
//     bodyAvatar: string,
//     btnIcon: string
// }

// interface IframeContextProps {
//   iframeData: IframeData | null;
// }

// // Create the Chatbot context
// export const IframeContext = createContext<IframeContextProps>({
//     iframeData: null,
// });

// // Create the Chatbot provider component
// export const IframeProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
//     const [iframeData, setIframeData] = useState<IframeData | null>( {
//         agentid:"" ,
//         customername: "",
//         botCardBackgroundColor: '',
//         botCardTextColor: '',
//         headerLogoColor:"#000",
//         chipHoverBackgroundColor: '',
//         chipHoverTextColor: '',
//         chipSelectedColor: '',
//         chipSelectedHoverColor: '',
//         userCardBorderColor: '',
//         infoTextTitleTextColor: '',
//         meetingCardTitleTextColor: '',
//         cardInfoChipsBackgroundColor: '',
//         cardInfoChipsBorderColor: '',
//         cardInfoChipsFontColor:'',
//         cardInfoSelectedChipsBackgroundColor:'',
//         cardInfoSelectedChipsBorderColor:'',
//         cardInfoSelectedChipsFontColor:'',
//         jobDetailsFormMainBackgroundColor: '',
//         formTextAreaBorderColor: '',
//         formTextAreaBackgroundColor: '',
//         formTextAreaActiveBorderColor: '',
//         formTextAreaActiveBackgroundColor: '',
//         formSubmitBtnBackgroundColor: '',
//         uploadBtnsBackgroundColor: '',
//         uploadBtnsTextColor: '',
//         chipSelectedBackgroundColor: '',
//         chipSelectedBorderColor: '',
//         chipSelectedHoverBorderColor: '',
//         enabledBtnBackgroundColor: '',
//         disabledBorderColor: '',
//         disabledTextColor: '',
//         chipSelectedHoverTextColor: '',
//         formTextAreaBorder2Color: '',
//         chipHoverBorderColor: '',
//         headerAvatar: '',
//         bodyAvatar: '',
//         btnIcon: '',
//     });

//     useEffect(() => {
//       const handler = (ev: any) => {
//         if(typeof ev.data !== "object" && ev.data){
//           let receivedData = JSON.parse(ev.data);
//           setIframeData(receivedData.var2)
//         }
//       }
//       window.addEventListener('message', handler)
    
//       // Don't forget to remove addEventListener
//       return () => window.removeEventListener('message', handler)
//     }, []);
  
//     return (
//       <IframeContext.Provider value={{ iframeData }}>
//         {children}
//       </IframeContext.Provider>
//     );
//   };


import React, { createContext, useState, ReactNode, useEffect, useContext } from 'react';

interface IframeData {
    agentid:string;
    customername: string,
    botCardBackgroundColor: string;
    botCardTextColor: string;
    headerLogoColor?:string;
    chipHoverBackgroundColor: string;
    chipHoverTextColor: string;
    chipSelectedColor: string;
    chipSelectedHoverColor: string;
    userCardBorderColor: string;
    infoTextTitleTextColor: string;
    meetingCardTitleTextColor: string;
    cardInfoChipsBorderColor: string;
    cardInfoChipsBackgroundColor: string;
    cardInfoChipsFontColor:string,
    cardInfoSelectedChipsBackgroundColor:string,
    cardInfoSelectedChipsBorderColor:string,
    cardInfoSelectedChipsFontColor:string,
    formTextAreaBorderColor: string;
    formTextAreaBackgroundColor: string;
    formTextAreaActiveBorderColor: string;
    formTextAreaActiveBackgroundColor: string;
    formSubmitBtnBackgroundColor: string;
    uploadBtnsBackgroundColor: string;
    uploadBtnsTextColor: string;
    jobDetailsFormMainBackgroundColor: string;
    chipSelectedBackgroundColor: string;
    chipSelectedBorderColor: string;
    chipSelectedHoverBorderColor: string;
    enabledBtnBackgroundColor: string;
    disabledBorderColor: string;
    disabledTextColor: string;
    chipSelectedHoverTextColor: string;
    chipHoverBorderColor: string;
    formTextAreaBorder2Color: string
    headerAvatar: string,
    bodyAvatar: string,
    btnIcon: string,
    botName:string;
    companyName:string;
    infoTextLinkBgColor:string;
    infoTextReadMoreLess:string;
}

interface IframeContextProps {
  iframeData: IframeData | null;
}

// Create the Chatbot context
export const IframeContext = createContext<IframeContextProps>({
    iframeData: null,
});

// Create the Chatbot provider component
export const IframeProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [iframeData, setIframeData] = useState<IframeData | null>( {
        agentid:"" ,
        customername: "",
        botCardBackgroundColor: '',
        botCardTextColor: '',
        headerLogoColor:"#000",
        chipHoverBackgroundColor: '',
        chipHoverTextColor: '',
        chipSelectedColor: '',
        chipSelectedHoverColor: '',
        userCardBorderColor: '',
        infoTextTitleTextColor: '',
        meetingCardTitleTextColor: '',
        cardInfoChipsBackgroundColor: '',
        cardInfoChipsBorderColor: '',
        cardInfoChipsFontColor:'',
        cardInfoSelectedChipsBackgroundColor:'',
        cardInfoSelectedChipsBorderColor:'',
        cardInfoSelectedChipsFontColor:'',
        jobDetailsFormMainBackgroundColor: '',
        formTextAreaBorderColor: '',
        formTextAreaBackgroundColor: '',
        formTextAreaActiveBorderColor: '',
        formTextAreaActiveBackgroundColor: '',
        formSubmitBtnBackgroundColor: '',
        uploadBtnsBackgroundColor: '',
        uploadBtnsTextColor: '',
        chipSelectedBackgroundColor: '',
        chipSelectedBorderColor: '',
        chipSelectedHoverBorderColor: '',
        enabledBtnBackgroundColor: '',
        disabledBorderColor: '',
        disabledTextColor: '',
        chipSelectedHoverTextColor: '',
        formTextAreaBorder2Color: '',
        chipHoverBorderColor: '',
        headerAvatar: '',
        bodyAvatar: '',
        btnIcon: '',
        botName:'',
        companyName:'',
        infoTextLinkBgColor:'',
        infoTextReadMoreLess:''
    });

    useEffect(() => {
      const handler = (ev: any) => {
        if(typeof ev.data !== "object" && ev.data){
          let receivedData = JSON.parse(ev.data);
          setIframeData(receivedData.var2)
        }
      }
      window.addEventListener('message', handler)
    
      // Don't forget to remove addEventListener
      return () => window.removeEventListener('message', handler)
    }, []);
  
    return (
      <IframeContext.Provider value={{ iframeData }}>
        {children}
      </IframeContext.Provider>
    );
  };