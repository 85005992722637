import React, { useContext, useState } from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { ReactNode } from 'react';
import style from '../chatBody/chatbody.module.scss';
import ResponseRenderer from '../repsonseRenderer'; // Adjust the path accordingly
import { IframeContext } from "../../iFrameContext";


interface InfoTextProps {
  children?: ReactNode;
  response: any[];
}

export default function InfoText({ response }: InfoTextProps) {
  const [expandedCardIndices, setExpandedCardIndices] = useState<number[]>([]);
  const { iframeData } = useContext(IframeContext);


  const toggleExpand = (index: number) => {
    setExpandedCardIndices((prevIndices) => {
      if (prevIndices.includes(index)) {
        return prevIndices.filter((i) => i !== index);
      } else {
        return [...prevIndices, index];
      }
    });
  };

  const truncateText = (text: string, maxWords: number) => {
    const words = text.split(' ');
    const truncatedWords = words.slice(0, maxWords);
    const truncatedText = truncatedWords.join(' ');
    return truncatedText;
  };

  const renderSubtitle = (subtitle: string, isExpanded: boolean, index: number) => {
    const maxWords = 20; // Adjust the maximum number of words as per your requirement
    const truncatedSubtitle = truncateText(subtitle, maxWords);
    const showReadMore = subtitle.length > truncatedSubtitle.length;

    if (isExpanded) {
      return (
        <>
          <ResponseRenderer htmlContent={subtitle} />
          {showReadMore && (
            <span
              style={{ color: `${iframeData?.infoTextReadMoreLess}`, cursor: 'pointer',marginTop:'3px' }}
              onClick={() => toggleExpand(index)}
            > Read less
            </span>
          )}
        </>
      );
    } else {
      return (
        <>
          <ResponseRenderer htmlContent={truncatedSubtitle} />
          {showReadMore && (
            <span
              style={{ color: `${iframeData?.infoTextReadMoreLess}`, cursor: 'pointer' }}
              onClick={() => toggleExpand(index)}
            >Read more
            </span>
          )}
        </>
      );
    }
  };

  return (
    <>
      {response.map((resp, i) => {
        const isExpanded = expandedCardIndices.includes(i);
        return (
          <Card
            sx={{
              display: 'flex',
              background: '#f3f0f0',
              width: 300,
              marginBottom: 2,
              height: 'auto',
              alignItems: 'center',
              padding: 0.5,
            }}
            key={i}
            className={`${style.infoTextMain} ${style.fadeIIn}`}
          >
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              {resp?.image?.src?.rawUrl && (
                <CardMedia
                  component="img"
                  sx={{ width: 35, height: 'fit-content' }}
                  image={resp?.image?.src?.rawUrl}
                  alt="no preview"
                />
              )}
              <CardContent>
                <Typography gutterBottom variant="h5" component="div" className={style.infoTextTitle}>
                  {resp?.title}
                </Typography>
                <Typography component="div" className={style.infoTextSubtitle}>
                  {renderSubtitle(resp?.subtitle, isExpanded, i)}
                </Typography>
              </CardContent>
            </Box>
          </Card>
        );
      })}
    </>
  );
}

