import React, { useState, useRef} from "react";
import { Button } from "@mui/material";
import style from "../chatBody/chatbody.module.scss";
interface JobDetailsFormProps {
  sessionId: string | null;
  onCombinedValues: (combinedValues: string) => void;
}

const JobDetailsForm = ({ sessionId,onCombinedValues }: JobDetailsFormProps) => {
  const chipClickRef = useRef<HTMLAudioElement | null>(null);  // for submit btn click sound effect
  const [error, setError] = useState("");
  const [isFormValid, setIsFormValid] = useState(false); 
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [formData, setFormData] = useState({
    jobTitle: "",
    experience: "",
    skillsRequired: "",
  });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    if (name === "experience") {
      // Clear the error message when the user starts typing again
      setError("");
    }

    setFormData({ ...formData, [name]: value });
  };

  const handleExperienceBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    if (name === "experience") {
      // Check if 'experience' is not a number
      if (!/^\d+(\.\d+)?$/.test(value)) {
        setError("Experience must be a valid number");
      }
    }
  };

    // Function to check form validity
  const checkFormValidity = () => {
      const isExperienceValid = /^\d+(\.\d+)?$/.test(formData.experience);
      return !error && formData.jobTitle !== "" && isExperienceValid && formData.skillsRequired !== "";
  };   
  
    // Update form validity whenever form data or error changes
  React.useEffect(() => {
      setIsFormValid(checkFormValidity() && !isSubmitted);
  }, [formData, error, isSubmitted]);

  const playAudio = (audioRef: React.RefObject<HTMLAudioElement>, delay:number) => {
    if (audioRef.current) {
      setTimeout(() => {
        if(audioRef.current) { // Additional null check
          audioRef.current.play();
          // 
        }
      }, delay);
      
    }
  }
  
  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>,
    values: any
  ) => {
    playAudio(chipClickRef, 0);
    event.stopPropagation();
    event.preventDefault();
    const combinedValues = `${values.jobTitle}/${values.experience}/${values.skillsRequired}`;
  // Call the onCombinedValues function with combinedValues as an argument
    if (typeof onCombinedValues === "function") {
      onCombinedValues(combinedValues);
    }
    setIsSubmitted(true); // Mark the form as submitted
  };

  return (
    <div className={style.jobDetailsFormMain}>
      <form
        onSubmit={(e) => handleSubmit(e, formData)}
        className={style.jobDetailsForm} >
       
        <label className={style.labelClass}>Search Query</label>
        <input
          name="jobTitle"
          value={formData.jobTitle}
          onChange={handleChange}
          className={style.formTextArea}
          placeholder="Android / Solution Architect" 
          autoComplete="off"
        />
          

        <label className={style.labelClass}>Total Experience</label>
        <input
          name="experience"
          value={formData.experience}
          onChange={handleChange}
          onBlur={handleExperienceBlur} 
          placeholder="5.2 years"
          className={style.formTextArea}
          autoComplete="off"
        />
  {error && <div className={`${style.errorMessage} ${style.errorText}`}>{error}</div>} {/* Display error message */}
        
        <label className={style.labelClass}>Skills</label>
        <input
          name="skillsRequired"
          value={formData.skillsRequired}
          onChange={handleChange}
          placeholder="Android, Scrum Master"
          className={style.formTextArea} 
          autoComplete="off"
        />
      
        <Button type="submit" variant="contained" className={`${style.formSubmitBtn} ${isFormValid ? '' : style.disabledButton}`} disabled={!isFormValid}>
          Search
        </Button>
      </form>

      <audio ref={chipClickRef} id="chipClickSound" controls={false} style={{display:'none'}}>
        <source src="/Sounds/chipClick.mp3" type="audio/mp3" />
      </audio>
    </div>
  );
};

export default JobDetailsForm;
