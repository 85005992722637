// import React, { useState } from "react";
// import { useMediaQuery } from "react-responsive";
// import "./App.css";
// import Chatbot from "./Components/chatBot/chatbot";
// import { IframeProvider } from "./iFrameContext";
// import WebFont from "webfontloader";
// // import chatOpenBtnBg from "../src/Images/chatBg.svg";
// // import chatOpenBtnIcon from "../src/Images/chatClosedIcon.svg";
// // import chatCloseBtnIcon from '../src/Images/newClosedIcon.svg';
// import chatGif from '../src/Images/chatGif.gif'

// const App = () => {
//   const deviceType = useMediaQuery({ maxWidth: 520 });
//   const isLessThan480px = useMediaQuery({ maxWidth: 480 });
//   const [isBotClosed, setBotClosed] = useState(false);

//   const toggleBot = () => {
//     setBotClosed(!isBotClosed);
//     // 
//   };

//   WebFont.load({
//     google: {
//       families: ["Mulish:500","Montserrat", "sans-serif"],
//     },
//   });

//   return (
//     <IframeProvider>
//       <div className="chatBotFrame">
//         <div className="btnImagesClosed" onClick={toggleBot}>
//           {/* <img src={chatOpenBtnBg} alt="CloseBG" className="chatOpenBtnBg" /> */}
//           {!isBotClosed ? ( <img src={chatGif} alt="ClosedImg" className="chatOpenBtnIcon" /> ) :  null}
//         </div>
//         {isBotClosed && <Chatbot deviceType={deviceType} toggling={toggleBot} />}
//       </div>
//     </IframeProvider>
//   );
// };

// export default App;

import React, { useContext, useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import "./App.css";
import Chatbot from "./Components/chatBot/chatbot";
import { IframeContext, IframeProvider } from "./iFrameContext";
import WebFont from "webfontloader";
import chatGif from '../src/Images/chatGif.gif'
import ChatBody from "./Components/chatBody/chatbody";

const App = () => {
  const deviceType = useMediaQuery({ maxWidth: 520 });
  const isLessThan480px = useMediaQuery({ maxWidth: 480 });
  const [isBotClosed, setBotClosed] = useState(false);
  const { iframeData } = useContext(IframeContext);
  // let contentWindow: boolean = false;

  const handleBotClick = () => {
    console.log(isBotClosed);
    
    if (isBotClosed) {
      // Bot is closed, so open it and show the button
      setBotClosed(p => !p);
    } else {
      // Bot is open, so close it and hide the button
      setBotClosed(p => !p);
    }
      
  };
  

  WebFont.load({
    google: {
      families: ["Mulish:500","Montserrat", "sans-serif"],
    },
  });

  return (
    <IframeProvider>
      <Chatbot></Chatbot>
    </IframeProvider>
  );
};

export default App;

