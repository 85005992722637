import React from 'react';
import styles from './loader.module.scss';

const Loader = () => {
  return (
    <div className={styles['loader-container']}>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      {/* <div>Loader is displayed</div> */}
    </div>
  );
};

export default Loader;
