import style from "./chatheader.module.scss";
import { useContext } from "react";
import { IframeContext } from "../../iFrameContext";
import logo from "../../Images/simechat-logo.png";
import CloseIcon from "@mui/icons-material/Close";
import MinimizeIcon from '@mui/icons-material/Minimize';
import { IconButton } from "@mui/material";

interface ChildComponentProps {
  handleClose: () => void;
  setFulfillmentTexts:any;
  pauseIdleTimer:any;
}

const ChatHeader: React.FC<ChildComponentProps> = ({ handleClose, setFulfillmentTexts, pauseIdleTimer }) => {
  const { iframeData } = useContext(IframeContext);  
 
  const handleCloseClick = () => {
    window.parent.postMessage("message",`*`);
    pauseIdleTimer();
    window.location.reload();
    
  };

  const handleMinimizeClick = () => {
    // console.log("inside minimize click");
    window.parent.postMessage("minimizeClick", `*`);
  }
  
  if (!iframeData) {
    return null; // Handle the case when the data is not available yet
  }

  return (
      <div className={style.chatheaderMain}>
    {/* logo */}
        <div className={`${style.box} ${style.chatheaderImg}`}>
          <img src={iframeData?.headerAvatar} alt="Bot Icon" className={style.chatheaderLogo} />
        </div>
    {/* simechat name */}
        <div className={`${style.box} ${style.chatheaderText}`}>
          <div className={style.chatMainText}>
            <div className={style.name}>{iframeData?.customername}</div>
            {/* <div className={style.status}>Online</div> */}
          </div>
        </div>
        {/* <div className={styles.chatHeaderSpeaker}></div> */}
    {/* minimize icon */}
    <div>
        <IconButton onClick={handleMinimizeClick} className={`${style.box} ${style.chatClose}`}>
          <div className={style.customIconButton}>
            <MinimizeIcon />
          </div>
        </IconButton>
        </div>
    {/* Close icon */}
        <div>
        <IconButton onClick={handleCloseClick} className={`${style.box} ${style.chatClose}`}>
          <div className={style.customIconButton}>
            <CloseIcon />
          </div>
        </IconButton>
        </div>
      </div>
  );
};

export default ChatHeader;
