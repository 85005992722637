import React, { useContext, useEffect } from 'react';
import { IframeContext } from "../iFrameContext";


interface ResponseRendererProps {
  htmlContent: string;
}

const ResponseRenderer: React.FC<ResponseRendererProps> = ({ htmlContent }) => {
  const { iframeData } = useContext(IframeContext);


  useEffect(() => {
  document.documentElement.style.setProperty('--infotext-link-background-color', iframeData?.infoTextLinkBgColor as string);
  document.documentElement.style.setProperty('--infoText-Read-More-Less',iframeData?.infoTextReadMoreLess as string)

},[iframeData?.infoTextLinkBgColor, iframeData?.infoTextReadMoreLess])

   // Render the parsed HTML content within a <div>.
  return <div dangerouslySetInnerHTML={{__html: htmlContent}} className='reponseRenderer' ></div>;
};

export default ResponseRenderer;

