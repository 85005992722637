import { ReactNode, useContext, useState } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { CardActions } from "@mui/material";
import Chip from "@mui/material/Chip";
import Tooltip from "@mui/material/Tooltip";
import style from "./chatbody.module.scss";
import { IframeContext } from "../../iFrameContext";


interface CardInfoProps {
  children?: ReactNode;
  response: any;
  onChipClick: (index: number, text: string) => void;
}

//services card-horizontal
export default function CardInfo({response,onChipClick}: CardInfoProps) {
  const [selectedChipIndex, setSelectedChipIndex] = useState<number | null>(null);
  const { iframeData } = useContext(IframeContext);
  // console.log("cardinfo",response);
  const handleChipClick = (index: number, text: string) => {
    onChipClick(index, text);
    setSelectedChipIndex(index); // Update the selected chip index
  };

  const handleCardClick = (actionLink: string) => {
    window.open(actionLink, "_blank");
  };

  const truncateSubtitle = (subtitle: string) => {
    const words = subtitle.split(" "); // Split the subtitle into words
    const truncatedWords = words.slice(0, 10); // Get the first 10 words
    const truncatedSubtitle = truncatedWords.join(" ");
    if (words.length > 10) {
      return truncatedSubtitle + "...";
    }
    return truncatedSubtitle;
  };

  const truncatedSubtitle = truncateSubtitle(response[2]?.subtitle);
 
  return (
    <Card sx={{ maxWidth: 345 }} className={`${style.cardService} ${style.fadeIIn}`} >
      <div onClick={() => handleCardClick(response[2]?.actionLink)}>
        <CardMedia component="img" height="120" image={response[1]?.rawUrl} alt="services" />
        <CardContent style={{padding:'none !important'}}>
          <Typography gutterBottom variant="h5" component="div" className={style.infoTextTitle}>
            {response[2]?.title}
          </Typography>
          <Typography variant="body2" color="text.secondary" className={style.cardInfoSubtitle}>
            {truncatedSubtitle}
          </Typography>
        </CardContent>
      </div>
      <CardActions className={style.chipstyle}>
        <div className={style.chipsMain} >
          {response[3]?.options?.length > 0 && (
            <div className={style.chipsContainer}>
              {response[3]?.options?.map((option: any, index: number) => (
                <Tooltip title={option?.text} key={index}>
                  <Chip label='Explore More' className={style.chips} 
                  style={{
                  background:selectedChipIndex === index ? iframeData?.cardInfoSelectedChipsBackgroundColor : iframeData?.cardInfoChipsBackgroundColor,
                  color: selectedChipIndex === index ? iframeData?.cardInfoSelectedChipsFontColor : iframeData?.cardInfoChipsFontColor,
                  border:"1px solid #AEC7BE !important", width:"97%",}}
                    onClick={() => handleChipClick(index, response[3]?.options?.[0]?.text)}
                  />
                </Tooltip>
              ))}
            </div>
          )}
        </div>
      </CardActions>
    </Card>
  );
}
