import axios from 'axios'
import { API_BASE_URL } from './endPoints'
import showAlert from '../showAlert'

// export const axiosInstance = axios.create({
//   baseURL: API_BASE_URL,
//   headers: {
//     Accept: 'application/json',
//     'Content-Type': 'application/json'
//   },
// })

axios.defaults.baseURL = API_BASE_URL // api base url
axios.interceptors.request.use(
  (config) => {
    const newConfig = config
    // newConfig.headers["Access-Token"] = Cookies.get("AccessToken") || "";
    return newConfig
  },
  (error) => Promise.reject(error)
)
axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response) {
      switch (error.response.status) {
        case 500:
          showAlert('Internal Server Error!', 'error')
          break
        case 402:
          showAlert(error.response.data, 'error')
          break
        case 401:
          showAlert('Unauthorized!', 'warning')
          window.location.href = '/'
          break
        case 404:
          showAlert('Something went wrong!', 'error')
          break
        case 403:
          showAlert('Access Forbidden!', 'warning')
          break
        case 400:
          if (!error.response.data) showAlert('Something went wrong!', 'error')
          break
        default:
          break
      }
    }

    return Promise.reject(error)
  }
)

export default {
  get: axios.get,
  post: axios.post,
  put: axios.put,
  patch: axios.patch,
  delete: axios.delete,
  all: axios.all,
  spread: axios.spread,
  interceptors: axios.interceptors,
  CancelToken: axios.CancelToken
}
