import { styled } from '@mui/material/styles';

export const StyledModalContainer = styled('div')(({ theme }) => ({
    display: "flex",
    flexDirection: "row",
     padding: "11px 28px",
    "& .MuiOutlinedInput-root": {
        '& fieldset': {
          border: 'none',
        },
    },
    "& .textfield":{
        width: "auto",
        backgroundColor: '#F5F5F5',
        borderRadius: '10px',
        height:"58px",
        maxHeight: "80px",
        background: "rgba(0, 0, 0, 0.05)",
        overflow: "hidden",
        "&::placeholder": {
          // fontStyle: "italic",
          fontSize: "16px",
          fontWeight: "400px",
          fontFamily: "Montserrat",
          fontStyle:"normal",
          color: "rgba(0, 0, 0, 0.3)"
    },
    "& textarea": {
        lineHeight: "2" // Adjust the line height as needed
      }
     
    
  },
 
  '& .icon': {
    padding: "0px 13px"
  },
  

  '& .pin': {
    transform: 'rotate(45deg)',
  },
  
}))

export const StyledFooter = styled('div')(({ theme }) => ({
    "& .footer": {
        textAlign: "center",
        padding: '10px 0',
        fontSize: 12,
        fontWeight: 400,
        color: "rgba(0, 0, 0, 0.3)",
        "& .brand": {
          fontSize: 14,
          fontWeight: 700,
          marginLeft:5,
          color: "#F87700"
        },
      }

}))