import axiosInstance from '../axiosInstance'
import { ENDPOINTS } from '../endPoints'

// const accessToken = 'abc'

interface ChatSubmitBody {
    agent_id: string,
    session_end: boolean,
    session_id: string,
    text: string,
    timezone: string,
    translate_code: string,
}

interface FileSubmitBody {
  data:{
    agent_id: string | undefined,
    session_end: boolean,
    session_id: string,
    timezone: string,
    translate_code: string,
    subject : string
    to_email:string,
    from_email:string,
    message_content: string,
  },
    file: File,
    [key: string]: any; // Index signature allowing any other properties with string keys
}


//platform API
export const OxylymServices = {
  chatSubmit: async (chatdetails: ChatSubmitBody) => {
    try {
      const response = await axiosInstance.post(ENDPOINTS.OXYLYM_DETAILS, chatdetails);

      return response
    } catch (error) {
      throw error
    }
  },

  fileSubmit: async (filedetails: FileSubmitBody) => {
    try {
      const formData = new FormData();
      for (let key in filedetails){
        if(key==="data"){
          // console.log("key===data : ",key)
          formData.append(key, JSON.stringify(filedetails?.[key]));
          // console.log("formData in key===data",formData);
        }
        else{
          formData.append(key, filedetails?.[key]);
          // console.log("formData in elsee",formData);
        }
      }
      
      const response = await axiosInstance.post(ENDPOINTS.FILE_UPLOAD, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          // Add any other headers if needed
        },
      });


      // console.log("response in filesunmit service",response)
      return response

    } catch (error) {
      throw error
    }
  }
}