import style from "./chatbody.module.scss";
import { useContext, useState, useEffect, useRef, Dispatch, SetStateAction } from "react";
import { IframeContext } from "../../iFrameContext";
import logo from "../../Images/avatarImg.svg";
import Card from "@mui/material/Card";
import Chip from "@mui/material/Chip";
import Loader from "../loader/loader";
import { Formik, Form, FormikHelpers } from "formik";
import { INITIAL_VALUES, VALIDATION_SCHEMA } from "./schema";
import Sendbtn from "../../../src/Images/sendBtn_new.svg";
import { IconButton, InputAdornment, TextField } from "@mui/material";
import { Mic } from "@mui/icons-material";
import { StyledFooter, StyledModalContainer } from "./styledComponents";
// import AttachFileIcon from "@mui/icons-material/AttachFile";
import { OxylymServices } from "../../utils/api/services/apiServices";
import React from "react";
import SpeechRecognition, {useSpeechRecognition} from "react-speech-recognition";
import CardInfo from "./cardInfo";
import InfoText from "./infoText";
import MeetingCard from "./meetingCard";
import JobDetailsForm from "./jobDetailsForm";
import ResumeUpload from "./uploadResume";
import next from "../../Images/next.svg";
import JobListing from "./jobListing";
import CardMedia from '@mui/material/CardMedia';
// import  '../../Sounds/mixkit-correct-answer-tone-2870.wav';

interface ChatBodyProps {
  responseData?: any; // Data received from ChatFooter
  onResponseData?: any;
  fulfillmentTexts:any;
  setFulfillmentTexts:any;
  timeoutResponse: any; 
  startIdleTimer:any;
  sessionId: string | null;
  endSession:boolean | undefined;
  updateEndSession:Dispatch<SetStateAction<boolean>>
}

const ChatBody: React.FC<ChatBodyProps> = ({
  responseData,onResponseData,fulfillmentTexts, setFulfillmentTexts,timeoutResponse, startIdleTimer, sessionId, endSession, updateEndSession,
}) => {
  const { iframeData } = useContext(IframeContext);
  const [fileSizeError, setFileSizeError] = useState<string>("");
  const [chipsOptions, setChipsOptions] = useState<any[]>([]);
  const [chipsetSelect, setChipsetSelect] = useState(false);
  // const [sessionId, setSessionId] = useState("");
  const [isRecording, setIsRecording] = useState(false);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);  //for getting the selected file from 'uploadResume' component
  // const fileInputRef = useRef<HTMLInputElement | null>(null);
  // const [_activeItems, _setActiveItems] = useState<any>({}); //for highlighting the selected chip
  const [isListening, setIsListening] = useState(false);
  const [subject, setSubject] = useState(""); // as payload for upload resume
  const [messageContent, setMessageContent] = useState(""); // as payload for upload resume
  const [toEmail, setToEmail] = useState("");// as payload for upload resume
  const [fromEmail,setFromEmail] = useState(""); // as payload for upload resume
  const [combinedValuesInParent, setCombinedValuesInParent] = useState("");
  const [uploadFile, setUploadFile] = useState<File | null>(null);
  let [formResponse, setFormResponse] = useState("");
  let isHorizontal = useRef<boolean>(false);
  let isTypeInfo = useRef<boolean>(false);
  // let isMeetingCard = useRef<boolean>(false);
  let isTypeForm = useRef<boolean>(false);
  // let isTypeUpload = useRef<boolean>(false);
  const { transcript } = useSpeechRecognition();
  const id = useRef(1); //for highlighting the selected chip
  let sevicesList = useRef<boolean>(false); //flag to do one API call in onSubmitHandler based on incoming response text
  let isInitialState = useRef<boolean>(true);// to set "Say hi to begin"
  const itemwidth = 290; //for left n right scroll of service cards
  let scrollbar = 0;
  const formChatRef= useRef<HTMLAudioElement | null>(null);
  const responsesReceivedRef = useRef<HTMLAudioElement | null>(null);
  const [activeChips, setActiveChips] = useState<{ [uid: number]: number }>(
    {});
  // const chipClickRef = useRef<HTMLAudioElement | null>(null);
  const [responseDatachip, setResponseDatachip] = useState<any>([]);
 
//  console.log("sessionId>>>>>>>>>>>>",sessionId)
  const moveleft = (event: any, idnum: number) => {
    // console.log("moveleft - itemwidth =",itemwidth + "scrollbar =",scrollbar);
    scrollbar = scrollbar <= itemwidth ? -itemwidth : scrollbar - itemwidth;
    document.getElementById(idnum.toString())?.scrollTo(scrollbar, 0);
  };

  useEffect(() => {
    if (fulfillmentTexts) {
      setFulfillmentTexts((prevFulfillmentTexts:any) => {
        const updatedFulfillmentTexts = [...prevFulfillmentTexts];
        // console.log(iframeData);

        updatedFulfillmentTexts[0] = {
          ...updatedFulfillmentTexts[0],
          text: `Hello, I am ${iframeData?.botName}, your virtual assistant from ${iframeData?.companyName}.`,
        };
        // console.log(updatedFulfillmentTexts);
        return updatedFulfillmentTexts;
      });
    }
  }, [iframeData]);

  const moveright = (event: any, idnum: number, textlength: number) => {
    // console.log("moveright - itemwidth =",itemwidth + "scrollbar =",scrollbar +"textlength = ",textlength);
    scrollbar = scrollbar + itemwidth >= textlength * itemwidth
        ? textlength * itemwidth
        : scrollbar + itemwidth;
    document.getElementById(idnum.toString())?.scrollTo(scrollbar, 0);
 
  };

  const onChipClick = async (i: number, chipText: string) => {
    setChipsetSelect(true);
    // playAudio(chipClickRef, 0);
    setFulfillmentTexts((prevFulfillmentTexts:any) => [
      ...prevFulfillmentTexts,
      {
        uid: 0,
        text: "Dummy value",
        flag: true,
        isUser: false,
        option: [],
        textLength: 2000
      },
    ]);

    startIdleTimer();
    const data = {
      agent_id: iframeData?.agentid,
      session_end: false,
      session_id: sessionId,
      text: chipText,
      timezone: "Asia/Calcutta",
      translate_code: "en",
    };

    try {
      // console.log("1")
      const response = await fetch(
        process.env.REACT_APP_API_BASE_URL + "voice/webhooks/chatbot",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      );

      if (!response.ok) {
        throw new Error("Request failed with status " + response.status);
      }

      const newResponseData = await response.json();
      setResponseDatachip(newResponseData); 
      // console.log("response on chipclick = ",newResponseData);
      const fulfillmentText = newResponseData?.payload;
      // console.log("newResponseData = ",newResponseData);

      updateEndSession(newResponseData?.EndSession?.toLowerCase() === "true"? true : false);


      if(fulfillmentText.length === 0){
        setFulfillmentTexts((prevFulfillmentTexts:any) => {
          const updatedFulfillmentTexts = prevFulfillmentTexts.slice(0, -1);
          return updatedFulfillmentTexts;
        });
      }
      // console.log("fulfillmentText",fulfillmentText[0]?.richContent?.[0]?.type);
      if(fulfillmentText[0]?.richContent?.[0]?.type === 'job_info'){
        jobListingResponse(fulfillmentText);
        // console.log("hreeeeeeee")
      }else{
        processResponse(fulfillmentText);
      }
      playAudio(responsesReceivedRef,500);
    } catch (error) {}
  };

  //for getting "subject,messageContent,toEmail,fromEmail" values from previous response & send them in uplaod resume API in handleResumeSend()
  useEffect(() => {
    if (responseDatachip?.payload !== undefined && responseDatachip?.payload.length > 0) {
        // console.log("responseDatachip+++++chatBody", responseDatachip);
       
        responseDatachip?.payload.forEach((item: any) => {
            if (item.richContent && item.richContent.length > 0) {
                const firstRichContent = item.richContent[0];

                if (firstRichContent.type === "email") {
                    // console.log("Matching chip_type found");
                    const options1 = JSON.parse(firstRichContent.title.replace(/'/g, '"'));
                    const subject = options1.subject;
                    const messageContent = options1.message_content;
                    const toEmail = options1.to_email;
                    const fromEmail = options1.from_email;
                    
                    setSubject(subject);
                    setMessageContent(messageContent);
                    setToEmail(toEmail);
                    setFromEmail(fromEmail);
                    // console.log(options1,subject,messageContent,toEmail,fromEmail)
                }
            }
        });
    }
}, [responseDatachip]);
 
  const handleChipClick = (i: number, chipText: string, uid: number) => {
    const numericDataObject: Record<string, number> = {};
      for (const key in activeChips) {
          if (activeChips.hasOwnProperty(key)) {
              numericDataObject[key] = parseFloat(key);
          }
      }
      // Check if the value exists in the object
      const exists: boolean = numericDataObject.hasOwnProperty(uid);

      if (exists) {
          // console.log(`The value ${uid} exists in the object.`);
      } else {
          onChipClick(i, chipText);
          setActiveChips({ ...activeChips, [uid]: i });
          // console.log(`The value ${uid} does not exist in the object.`);
      }
          // Set the active chip for the current set
          // console.log("activeChips =",activeChips);
  };

  const isChipActive = (uid: number, index: number) => {
    return activeChips[uid] === index;
  };

  const handleCombinedValues = async (combinedValues: string) => {
    // console.log("combinedValues",combinedValues);
    setCombinedValuesInParent(combinedValues);

//to set loader while we wait for the response
    setFulfillmentTexts((prevFulfillmentTexts:any) => [
      ...prevFulfillmentTexts,
      {
        uid: id.current++,
        text: "Dummy value",
        flag: true,
        isUser: false,
        option: [],
        textLength: 2000,
      },
    ]);

    const data = {
      agent_id: iframeData?.agentid,
      session_end: false,
      session_id: sessionId,
      text: combinedValues,
      timezone: "Asia/Calcutta",
      translate_code: "en",
    };
  
    // console.log("2")
    const response = await fetch(
      process.env.REACT_APP_API_BASE_URL + "voice/webhooks/chatbot",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      }
    );

    // console.log('form response as it is',response);

    if (!response.ok) {
      throw new Error("Request failed with status " + response.status);
    }

    let formResp = await response.json();
    setFormResponse(formResp);
    if(formResp?.payload.length === 0){
      setFulfillmentTexts((prevFulfillmentTexts:any) => {
        const updatedFulfillmentTexts = prevFulfillmentTexts.slice(0, -1);
        return updatedFulfillmentTexts;
      });
    }
    updateEndSession(formResp?.EndSession?.toLowerCase() === "true"? true : false);
    jobListingResponse(formResp?.payload);
    playAudio(responsesReceivedRef,500);
  };

  const onSubmitHandler = async (
    values: { chat: string },
    formikHelpers: FormikHelpers<{ chat: string }>
  ) => {
    isInitialState.current = false;
    playAudio(formChatRef,0);
    const { resetForm } = formikHelpers;

    // Check if the input contains an email with '@simelabs.com' domain
    const emailPattern = /@simelabs\.com$/i;
    if (uploadFile) {
          handleSendResume(uploadFile);
      }
    else {
      if (values.chat) {
        startIdleTimer();
        if(emailPattern.test(values.chat)){
          alert("Come on you sneaky colleague! No '@simelabs.com' emails allowed - we're on to you!");
        } else{
        const data = {
          agent_id: iframeData?.agentid,
          session_end: false,
          session_id: sessionId,
          text: values.chat || transcript || combinedValuesInParent,
          timezone: "Asia/Calcutta",
          translate_code: "en",
        };

        // list services infoCard       
        if(sevicesList.current){
          sevicesList.current = false;
          // fulfillmentObjects is to set the values that are typed in d form to FulfillmentTexts[]
          const fulfillmentObjects = [
            {
              text: values.chat,
              flag: false, // Set flag as true for the first object to display logo,
              isUser: true,
              option: [],
              textLength: 1,
              // isHorizontal: false,
              uid: id.current++,
            },
          ];
  
        // console.log("fulfillmentObjects",fulfillmentObjects)

          setFulfillmentTexts((prevFulfillmentTexts:any) => [
            ...prevFulfillmentTexts,
            ...fulfillmentObjects,
          ]);
          playAudio(formChatRef,0);

          resetForm();
  
          setFulfillmentTexts((prevFulfillmentTexts:any) => [
            ...prevFulfillmentTexts,
            {
              text: "Dummy value",
              flag: true,
              isUser: false,
              option: [],
              textLength: 2000,
              uid: 0
            },
          ]);

         try {
          // console.log("3")
            const response = await fetch(
              process.env.REACT_APP_API_BASE_URL + "voice/webhooks/chatbot",
              {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify(data),
              }
            );
      
            if (!response.ok) {
              throw new Error("Request failed with status " + response.status);
            }
      
            // this section is to display the infoCard horizontally
            const responseData = await response.json();
            const richContentArray:any[] = [];
            const fullFillmentTextArray:any[] = [];
            let options: any[];

            updateEndSession(responseData?.EndSession?.toLowerCase() === "true"? true : false);
             const fulfillmentText = responseData?.payload;
            if(fulfillmentText.length === 0){
              setFulfillmentTexts((prevFulfillmentTexts:any) => {
                const updatedFulfillmentTexts = prevFulfillmentTexts.slice(0, -1);
                return updatedFulfillmentTexts;
              });
            }

            let text: string = '';
            const newResponse = fulfillmentText?.forEach((item:any) => {
              if (item.hasOwnProperty('richContent')) {
                richContentArray.push(item.richContent);
              }
              if (item.hasOwnProperty('fullFillmentText')) {
                fullFillmentTextArray.push(item.fullFillmentText);
              }
            });
            const chipHorizontal = richContentArray?.[0]?.[0]?.[0]|| [];
            // console.log("chipHorizontal = ",chipHorizontal);
      
            //for horizontal scroll of services
            if (chipHorizontal.chip_type === "horizontal") {
              isHorizontal.current = true;            
            } else {
              isHorizontal.current = false;
            }

            //for horizontal scroll of info
            if (chipHorizontal.type === "info") {
              isTypeInfo.current = true;
              // alert("submit handler");
            } else {
              isTypeInfo.current = false;
            }
      
            setFulfillmentTexts((prevFulfillmentTexts:any) => {
              const updatedFulfillmentTexts = prevFulfillmentTexts.slice(0, -1);
              return updatedFulfillmentTexts;
            });
      
            if(richContentArray !== undefined){
              options = (richContentArray?.[0]?.[0]?.[0]?.options) || 
                        (richContentArray?.[0]?.[0]?.[0]?.options.type === "info") ||
                        (richContentArray?.[0]?.[0]?.[0]?.options.type === "chips") ||
                        (richContentArray?.[0]?.[0]?.[0]) || [];
            } else {
              options = [];
              setChipsOptions([]);
            }
          
            console.log("fulfillmentText b4 checks= ",fullFillmentTextArray);
            if (fullFillmentTextArray && fullFillmentTextArray.length > 0 && !isHorizontal.current && !isTypeInfo.current && !isTypeForm.current) {
              const fulfillmentObjects = fulfillmentText.map(
                (text: any, index: number) => ({
                  text,
                  flag: index === 0, // Set flag as true for the first object
                  isUser: false,
                  option: index === fullFillmentTextArray?.length - 1 ? options : [],
                  textLength: fulfillmentText.length,
                  isHorizontal: false,
                  uid: id.current++,
                  isInfo: false,
                  cardOrder: false,
                  isJobForm:false,
                  isUpload:false
                })
              );
              // alert(2);
              console.log("fulfillmentObjects !horizontal in handler= ",fulfillmentObjects)
              setFulfillmentTexts((prevFulfillmentTexts:any) => [
                ...prevFulfillmentTexts,
                ...fulfillmentObjects,
              ]);
            }
            // console.log("fulfillmentText........",fulfillmentText)
            if (fulfillmentText && fulfillmentText.length > 0 && (isHorizontal.current || isTypeInfo.current || isTypeForm.current)) {
              if (responseData.payload.richContent !== undefined) {
                options = responseData?.payload?.richContent || [];
              } else {
                options = [];
                setChipsOptions([]);
              }
      
              // console.log("fulfillmentText = ",fulfillmentText);
              // if (fulfillmentText.length === 1 && options.length > 0) {
              //   fulfillmentText.push("");
              // }
      
              const fulfillmentHorizontal = fulfillmentText.map(
                (text: any, index: number) => ({
                  text,
                  flag: index === 0,
                  isUser: false,
                  option: options,
                  textLength: 0,
                  isHorizontal: isHorizontal.current,
                  uid: id.current++,
                  isInfo: isTypeInfo.current,
                  cardOrder: false,
                  isJobForm:isTypeForm.current,
                  isUpload:false
                })
              );
              console.log("fulfillmentHorizontal in handler= ", fulfillmentHorizontal);
      
              setFulfillmentTexts((prevFulfillmentTexts:any) => {
                const updatedFulfillmentTexts = [
                  ...prevFulfillmentTexts,
                  ...fulfillmentHorizontal,
                ];
                return updatedFulfillmentTexts;
              });
            }
      
            if (options && options.length > 0) {
              setChipsOptions(options);
            }

            resetForm();
          } catch (error) {}

        } else{
        // fulfillmentObjects is to set the values that are typed in d form to FulfillmentTexts[]
          const fulfillmentObjects = [
            {
              text: values.chat,
              flag: false, // Set flag as true for the first object,
              isUser: true,
              option: [],
              textLength: 1,
              isHorizontal: false,
              uid: id.current++,
              isInfo: false,
              cardOrder: false,
              isJobForm:false,
              isUpload:false
            },
          ];
  
        //  alert(2)
          setFulfillmentTexts((prevFulfillmentTexts:any) => [
            ...prevFulfillmentTexts,
            ...fulfillmentObjects,
          ]);
          playAudio(responsesReceivedRef,500);

  
          setFulfillmentTexts((prevFulfillmentTexts:any) => [
            ...prevFulfillmentTexts,
            {
              text: "Dummy value",
              flag: true,
              isUser: false,
              option: [],
              textLength: 2000,
              isHorizontal: false,
              uid: 0,
              isInfo: false,
              cardOrder: false,
              isJobForm:false,
              isUpload:false
            },
          ]);
  
          onResponseData(data, "application/json");
        }
          resetForm();
        }
      }
    }
  };

  const handleSendResume = async (selectedFile: File) => {
      if (selectedFile) {
      // setSelectedFile(null); // Reset selected file after sending

      setFulfillmentTexts((prevFulfillmentTexts:any) => [
        ...prevFulfillmentTexts,
        {
          text: "Dummy value",
          flag: true,
          isUser: false,
          option: [],
          textLength: 2000,
          uid: 0
        },
      ]);

      const data1 = await OxylymServices.fileSubmit({
        data: {
          agent_id: iframeData?.agentid,
          session_end: false,
          session_id: sessionId ?? "",
          timezone: "Asia/Calcutta",
          translate_code: "en",
          subject: subject,
          to_email:toEmail,
          from_email:fromEmail,
          message_content: messageContent,
        },
        file: selectedFile,        
      });

//to display the uploaded file response "mail delivered" in bot section coz its response format is different
      setFulfillmentTexts((prevFulfillmentTexts:any) => {
        const updatedFulfillmentTexts = prevFulfillmentTexts.slice(0, -1);
        return updatedFulfillmentTexts;
      });

      const fulfillmentObjects = [
              {
                text: selectedFile.name, // Add the selected file name here
                flag: false,
                isUser: true,
                option: [],
                textLength: 1,
                uid: id.current++
              }
            ];

            setFulfillmentTexts((prevFulfillmentTexts:any) => [
              ...prevFulfillmentTexts,
              ...fulfillmentObjects,
            ]);

       
        setFulfillmentTexts((prevFulfillmentTexts:any) => [
          ...prevFulfillmentTexts,
          {
            text: "Dummy value",
            flag: true,
            isUser: false,
            option: [],
            textLength: 2000,
            uid: 0
          },
        ]);

//api call parallel to send btn click to upload resume
        const data = {
          agent_id: iframeData?.agentid,
          session_end: false,
          session_id: sessionId,
          text: 'resumeUploaded',
          timezone: "Asia/Calcutta",
          translate_code: "en",
        };
  
        // console.log("4")
        const response = await fetch(
          process.env.REACT_APP_API_BASE_URL + "voice/webhooks/chatbot",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
          }
        );
  
        if (!response.ok) {
          throw new Error("Request failed with status " + response.status);
        }
  
        const resumeSentResponse = await response.json();
        const resumeUpload = resumeSentResponse?.payload;
        updateEndSession(resumeSentResponse?.EndSession?.toLowerCase() === "true"? true : false);
        // console.log("resumeSentResponse",resumeSentResponse);
        processResponse(resumeUpload);
        playAudio(responsesReceivedRef,500)
        // resetForm();
        // setSelectedFile(null);
    }
  };

  //first useeffect of chatbody
  useEffect(() => {
    if (iframeData && responseData) {
      // const fulfillmentText = responseData?.fullFillmentText;
      const fulfillmentText = responseData?.payload;
      console.log("fulfillmentText in useeffect = ",fulfillmentText);
      processResponse(fulfillmentText);
    }
  }, [iframeData, responseData]);

  useEffect(() => {
    // Update CSS variable
    document.documentElement.style.setProperty('--card-background-color', iframeData?.botCardBackgroundColor as string);
    document.documentElement.style.setProperty('--card-text-color', iframeData?.botCardTextColor as string);
    document.documentElement.style.setProperty('--header-logo-color', iframeData?.headerLogoColor?iframeData?.headerLogoColor as string:"#000");

    document.documentElement.style.setProperty('--chip-hover-background-color', iframeData?.chipHoverBackgroundColor as string);
    document.documentElement.style.setProperty('--chip-hover-text-color', iframeData?.chipHoverTextColor as string);
    document.documentElement.style.setProperty('--chip-selected-color', iframeData?.chipSelectedColor as string);
    document.documentElement.style.setProperty('--chip-selected-hover-color', iframeData?.chipSelectedHoverColor as string);
    document.documentElement.style.setProperty('--userCard-border-color', iframeData?.userCardBorderColor as string);
    document.documentElement.style.setProperty('--infoTextTitle-text-color', iframeData?.infoTextTitleTextColor as string);
    document.documentElement.style.setProperty('--meetingCradTitle-text-color', iframeData?.meetingCardTitleTextColor as string);
    document.documentElement.style.setProperty('--cardInfoChips-background-color', iframeData?.cardInfoChipsBackgroundColor as string);
    document.documentElement.style.setProperty('--cardInfoChips-border-color', iframeData?.cardInfoChipsBorderColor as string);
    document.documentElement.style.setProperty('--jobDetailsFormMain-background-color', iframeData?.jobDetailsFormMainBackgroundColor as string);
    document.documentElement.style.setProperty('--formTextArea-border-color', iframeData?.formTextAreaBorderColor as string);
    document.documentElement.style.setProperty('--formTextArea-background-color', iframeData?.formTextAreaBackgroundColor as string);
    document.documentElement.style.setProperty('--formTextArea-active-border-color', iframeData?.formTextAreaActiveBorderColor as string);
    document.documentElement.style.setProperty('--formTextArea-active-background-color', iframeData?.formTextAreaActiveBackgroundColor as string);
    document.documentElement.style.setProperty('--formSubmitBtn-background-color', iframeData?.formSubmitBtnBackgroundColor as string);
    document.documentElement.style.setProperty('--uploadBtns-background-color', iframeData?.uploadBtnsBackgroundColor as string);
    document.documentElement.style.setProperty('--uploadBtns-text-color', iframeData?.uploadBtnsTextColor as string);
    document.documentElement.style.setProperty('--chip-selected-background-color', iframeData?.chipSelectedBackgroundColor as string);
    document.documentElement.style.setProperty('--chip-selected-border-color', iframeData?.chipSelectedBorderColor as string);
    document.documentElement.style.setProperty('--chip-selected-hover-border-color', iframeData?.chipSelectedHoverBorderColor as string);
    document.documentElement.style.setProperty('--enabledBtn-background-color', iframeData?.enabledBtnBackgroundColor as string);
    document.documentElement.style.setProperty('--disabled-border-color', iframeData?.disabledBorderColor as string);
    document.documentElement.style.setProperty('--disabled-text-color', iframeData?.disabledTextColor as string);
    document.documentElement.style.setProperty('--chip-selected-hover-text-color', iframeData?.chipSelectedHoverTextColor as string);
    document.documentElement.style.setProperty('--chip-hover-border-color', iframeData?.chipHoverBorderColor as string);
    document.documentElement.style.setProperty('--formTextArea-border2-color', iframeData?.formTextAreaBorder2Color as string);
  }, [iframeData?.botCardBackgroundColor, iframeData?.botCardTextColor,iframeData?.chipHoverBackgroundColor,  iframeData?.chipHoverTextColor,iframeData?.chipSelectedColor,  iframeData?.chipSelectedHoverColor,
    iframeData?.userCardBorderColor,iframeData?.infoTextTitleTextColor, iframeData?.meetingCardTitleTextColor, iframeData?.cardInfoChipsBackgroundColor,iframeData?.cardInfoChipsBorderColor, iframeData?.jobDetailsFormMainBackgroundColor, 
    iframeData?.formTextAreaBorderColor, iframeData?.formTextAreaBackgroundColor,  iframeData?.formTextAreaActiveBorderColor,  iframeData?.formTextAreaActiveBackgroundColor,  iframeData?.formSubmitBtnBackgroundColor,  iframeData?.uploadBtnsBackgroundColor,
    iframeData?.uploadBtnsTextColor,iframeData?.chipSelectedBackgroundColor,  iframeData?.chipSelectedBorderColor,iframeData?.chipSelectedHoverBorderColor, iframeData?.enabledBtnBackgroundColor, iframeData?.disabledBorderColor,iframeData?.disabledTextColor,
    iframeData?.chipSelectedHoverTextColor, iframeData?.chipHoverBorderColor,  iframeData?.formTextAreaBorder2Color 
   ]);

  //switch code
  const processResponse = (response:any) => {
    // console.log("inside processResponse response = ", response);
    setFulfillmentTexts((prevFulfillmentTexts:any) => {
      const updatedFulfillmentTexts = prevFulfillmentTexts.slice(0, -1);
      return updatedFulfillmentTexts;
    });
    response.forEach((item:any, index:number) => {
        if (item.fullFillmentText) {
          // console.log("in FT of item processResponse",item);
            displayText(item.fullFillmentText, index);
        } 
        else if (item.richContent) {
          console.log("in RC of processResponse item",item);
          let data:any[] = [];         
          if(item.richContent.length === 1){
            // console.log("item.richContent[0][0].chip_type");
            if(item.richContent[0]?.length){
              // console.log("heree");              
              if(item.richContent[0][0]){
                // console.log("insideee");                
                if(item.richContent[0][0].chip_type === "job_listing"){
                  data = [{chip_type: "job_listing"}]
                  // console.log("data in job listing",data);
                }  else {
                  // console.log("..weweqww....")
                  data = item.richContent[0];
                }
              }
              else {
                // console.log("......")
                data = item.richContent[0];
              }
              
            }else{
              // console.log("...sdjfkds...")
              data = [item.richContent[0]];
            }
            // console.log("dataa ",data);
           }else if(item.richContent[0]?.[0]?.chip_type === "horizontal"){
            data = [{chip_type: "horizontal"}]
            // console.log("data",data);
          }          
          else if(item.richContent[0]?.[0]?.chip_type === "job_listing"){
            data = [{chip_type: "job_listing"}]
            // console.log("data in job listing",data);
          }
           else{
            // console.log("..==1....")
            item.richContent.forEach((i:any) => {
              i.forEach((e:any) => {
                data.push(e);
                // console.log("dtatsd",data);
              })
            });
            
          } 
            data.forEach((j:any, index:number) => {
              console.log("J",j);
                switch (j.type) {
                    case "chips":
                      displayRichcontent(j.options, "chips", 3);
                        break;
                    
                    case "info":
                      // console.log("infoooo***",j);
                      if(j.options){
                        displayRichcontent(j.options, "info", 3);
                      }
                      else if(j.faq_flag){
                        displayRichcontent([j],"infotext", 0)
                      }
                      else if(j.actionLink){
                        displayRichcontent([j],"scheduleMeeting", 0)
                      }
                      else if(j.subtitle){
                        displayRichcontent([j],"infotext", 3)
                      }else{
                        displayText(j.title,index);
                      }
                        break;
                    
                    case "email":
                      displayRichcontent(item.richContent, "email", 3);
                      break;

                    case "video":
                      displayVideoWidget(j.source.anchor.href, index);
                      break;

                    case "image":
                      console.log("dsadasd = ",j.image.rawUrl);
                      displayImage(j.image.rawUrl, "image", index);
                      break;
            
                    default:
                      break;
                }

                switch (j.chip_type) {                  
                    case "horizontal":
                      // console.log("item.richContent",item.richContent)
                      displayRichcontent(item.richContent,"horizontal",3)
                      break;

                    case "form":
                      displayRichcontent([], "form", 3);
                      break;

                    case "job_listing":
                      // console.log("item.richContent in joblisting",item.richContent)
                      displayRichcontent(item.richContent,"jobListing",3)
                      break;
  
                    default:
                    break;
                }     
              }
            );
        }
    });
  }

  const displayText = (text: any, index:number) => {
    // console.log("Text displayText:", text, index);
      const displayTextValues =[ {
          text:text ,
          flag: index === 0,
          isUser: false,
          option: [],
          textLength: 3
        }]
      
      setFulfillmentTexts((prevFulfillmentTexts:any) => [
        ...prevFulfillmentTexts,
        ...displayTextValues,
      ]);
  }

  const displayRichcontent = (chips:any, type:any, index:number) => {
    // console.log("chips **********",chips);
    const displayRCValues = [{
        text: '',
        type:type,
        flag: index === 0,
        isUser: false,
        option: chips,
        textLength: 3,
        uid:Math.random()
      }];
    
    // console.log("displayRCValues",displayRCValues);
    // console.log(fulfillmentTexts, '--------', carosilContent);
    setFulfillmentTexts((prevFulfillmentTexts:any) => [
      ...prevFulfillmentTexts,
      ...displayRCValues,     
    ]);
  }

  const displayImage = (imgUrl:string, type:any, index:number) => {
    console.log("imgUrl **********",imgUrl);

    const displayImageValue = [{
      text: '',
        type: type,
        flag: index === 0,
        isUser: false,
        option: [{href: imgUrl}],
        textLength: 3,
        uid: Math.random()
    }]

    setFulfillmentTexts((prevFulfillmentTexts:any) => [
      ...prevFulfillmentTexts,
      ...displayImageValue,

  ]);

  }
  const displayVideoWidget = (videoUrl: string, index: number) => {

    const url = videoUrl; 
    // console.log("url = ",url);
    const urlParams = new URLSearchParams(new URL(url).search);
    console.log("urlParams = ",urlParams); 
    const vValue = urlParams.get('v');
    console.log("vvalue = ",vValue);
    const displayVideoValues = [{
        text: '',
        type: 'video',
        flag: index === 0,
        isUser: false,
        option: [{href: vValue}],
        textLength: 3,
        uid: Math.random()
    }];
    
    setFulfillmentTexts((prevFulfillmentTexts:any) => [
        ...prevFulfillmentTexts,
        ...displayVideoValues,
    ]);

    // console.log("setFulfillmentTexts in video = ",setFulfillmentTexts);
  }

  const jobListingResponse = (response:any) => {
    // console.log("inside jobListingResponse response = ", response);
    response.forEach((item:any) => {
      // console.log("jobListingResponse item",item);
      if(item.richContent[0].type === "job_info"){
          const jobResponse = item.richContent.slice(1, 5);
          // console.log("jobResponse = ",jobResponse);
          processResponse(jobResponse);
      }
    })
  }
// Scroll to the bottom of the chat container when fulfillmentTexts change
  useEffect(() => {
    if (fulfillmentTexts.length > 0) {
      const chatContainer = document.getElementById("chatContainer");
      if (chatContainer) {
        chatContainer.scrollTop = chatContainer.scrollHeight;
      }
    }
  }, [fulfillmentTexts]);

//according to response height
  // useEffect(() => {
  //   if (fulfillmentTexts.length > 0) {
  //     const chatContainer = chatContainerRef.current;
  //     console.log("chatContainer = ",chatContainer);
  //     if (chatContainer) {
  //       // You can customize the scroll position here.
  //       // For example, scroll to the middle of the chat container.
  //       const middlePosition = chatContainer.scrollHeight / 1.5;
  //       chatContainer.scrollTop = middlePosition;

  //       console.log("chatContainer.scrollHeight = ",chatContainer.scrollHeight);
  //       console.log("chatContainer.scrollTop = ",chatContainer.scrollTop);
        
  //     }
  //   }
  // }, [fulfillmentTexts]);

  //animation related useffect
  useEffect(() => {
    // Add a delay to allow DOM updates before applying the animation class
    const timer = setTimeout(() => {
      const messageContainers = document.querySelectorAll(`.${style.slideInRright}`);
      messageContainers.forEach(container => {
        container.classList.add('animated');
      });
    }, 100);

    return () => {
      clearTimeout(timer);
    };
  }, [fulfillmentTexts]);

  const submitForm = (values: { chat: string }) => {
    const { chat } = values;
    const formikHelpers: any = {
      resetForm: () => {},
      setStatus: () => {},
      setErrors: () => {},
      setSubmitting: () => {},
      setTouched: () => {},
      setValues: () => {},
    };
    onSubmitHandler({ chat }, formikHelpers);
  };

  const handleStartListening = () => {
    if (!isListening) {
      setIsRecording(true);
      SpeechRecognition.startListening();
      setIsListening(true);
    }
  };

  const handleStopListening = () => {
    if (isListening) {
      SpeechRecognition.stopListening();
      setIsListening(false);
      submitForm({ chat: transcript });
      setIsRecording(false);
    }
  };

  //for sound effect
  const playAudio = (audioRef: React.RefObject<HTMLAudioElement>, delay:number) => {
    if (audioRef.current) {
      setTimeout(() => {
        if(audioRef.current) { // Additional null check
          audioRef.current.play();
          // console.log("audioRef.current", audioRef.current);
        }
      }, delay);
      
    }
  }    
  console.log("fulfillmentTexts [] = ",fulfillmentTexts)
  // console.log("chatbody session = ",sessionId);

  return (
    <div style={{display:"flex", flexDirection:"column", flexGrow:1, overflow:"hidden"}}>
      <div className={style.chatBody} id="chatContainer">
        {fulfillmentTexts.length > 0 && fulfillmentTexts.map((text: any, index: number) => (
            <React.Fragment key={index}>
              {text.isUser && (
                <div className={`${style.userMessage} ${style.slideInRright}`}>
                  <div className={`${style.box} ${style.userMess}`}>
                    <Card variant="outlined" className={style.userCard}>
                      {text.text}
                    </Card>
                  </div>
                </div>
              )}
              {!text.isUser && (
                <div className={style.botMessage}>
                  <div className={`${style.box} ${style.chatbotImg}`}>
                    {text.flag === true && (
                      <img src={iframeData?.bodyAvatar} alt="Bot Icon" className={style.chatLogo}/>
                    )}
                  </div>
                  
                  <div className={`${style.box} ${style.fadeIIn}`}>
                      <div className={style.cardContainer}>
                        {Boolean(text.text) && text.textLength !== 2000 && (
                          <Card variant="outlined" className={style.botCard}>
                            {text.text}
                          </Card>
                        )}
                        {text.textLength === 2000 && <Loader />}
                      </div>

                        {/* Render the YouTube video widget */}
                        { text.type === "video" && (
                              <div className={style.videoContainer}>
                                  <iframe
                                      width="100%"
                                      height="315"
                                      src={`https://www.youtube.com/embed/${text.option[0].href}?controls=1&autoplay=0&cc_load_policy=1&fs=1`}
                                      title="YouTube video player"
                                      frameBorder="0"
                                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen"
                                      allowFullScreen
                                  ></iframe>
                              </div>
                        )}


                        { text.type === 'image' && (
                        <CardMedia
                          component="img"
                          sx={{ width: '105%', height: 'fit-content', marginBottom:'10px', borderRadius:'2px' }}
                          image={`${text.option[0].href}`}
                          alt="no preview"
                        />)}

                      
                      { <div className={style.chipsMain}>
                          {text?.type === "chips" && (
                              <div className={style.chipsContainer}>
                                {text?.option?.map(
                                  (option: any, index: number) => (
                                    <Chip
                                      key={index}
                                      className={`${style.chips} ${isChipActive(text?.uid || 0, index) ? 'selected' : ''}`}
                                      label={option?.text}
                                      href={option?.link}
                                      component="a"
                                      style={isChipActive(text?.uid || 0, index)
                                              ? {
                                                  background: iframeData?.chipSelectedBackgroundColor,
                                                  color:iframeData?.chipSelectedColor,
                                                  border: `1px solid ${iframeData?.chipSelectedBorderColor}`  
                                                }
                                              : { background: "#fff", 
                                                  border:"1px solid #d8d8d8"
                                                }
                                            }
                                      onClick={() => {handleChipClick(index, option.text, text.uid); }}
                                       disabled={isChipActive(text?.uid || 0, index) ? false : activeChips[text?.uid] !== undefined} 
                                    />
                                  )
                                )}
                              </div>
                            )
                          }
                        </div>
                      }

                      {text.type === "horizontal" && (
                        <div className={style.chipsMain} style={{marginLeft:'-35px'}}>
                          {text?.option?.length > 0 && (
                            <div className={style.wrapper}>
                              <button onClick={(e) => moveleft(e, text.uid)} className={style.slideleftContainer} >
                                <img src={next} alt="next" className={style.arrowImg}/>
                              </button>
                              <div className={style.cardOuterContainer}>
                                <div className={style.cardInnerContainer} id={text.uid}>
                                  <div className={style.chipsContainerCard}>
                                    {text?.option?.map((option: any, index: number) => (
                                        <CardInfo
                                          response={option}
                                          key={index}
                                          onChipClick={onChipClick}
                                        ></CardInfo>
                                      )
                                    )}
                                  </div>
                                </div>
                              </div>
                              <button onClick={(e) => moveright(e, text.uid, text.option.length)} className={style.sliderightContainer} >
                                <img src={next} alt="previous" className={style.arrowImg}/>
                              </button>
                            </div>
                          )}
                        </div>
                      )}

                      {text.type === "jobListing" && (
                        <div className={style.chipsMain} 
                        style={{ marginLeft: text.option.length > 1 ? '-35px' : '0' }}
                        >
                          {text?.option?.length > 0 && (
                            <div className={style.wrapper} style={{maxWidth:'21rem'}}>
                              {text.option.length > 1 && (
                              <button onClick={(e) => moveleft(e, text.uid)} className={style.slideleftContainer} style={{marginTop:'5rem'}}>
                                <img src={next} alt="next" className={style.arrowImg}/>
                              </button>
                              )}
                              <div className={style.cardOuterContainer} style={{height:'210px', maxWidth:'22rem'}}>
                                <div className={style.cardInnerContainer} id={text.uid} style={{height:'230px'}}>
                                  <div className={style.chipsContainerCard}>
                                    {text?.option?.map((option: any, index: number) => (
                                        <JobListing
                                          response={option}
                                          key={index}
                                          onChipClick={onChipClick}
                                        ></JobListing>
                                      )
                                    )}
                                  </div>
                                </div>
                              </div>
                              {text.option.length > 1 && (
                              <button onClick={(e) => moveright(e, text.uid, text.option.length)} className={style.sliderightContainer} style={{marginTop:'5rem'}}>
                                <img src={next} alt="previous" className={style.arrowImg}/>
                              </button>
                              )}
                            </div>
                          )}
                        </div>
                      )}

                      {text.type === "infotext" && (
                        <>                  
                          {(
                            <>
                              {text?.option?.map(
                                (options: any, index: number) => (
                                  <InfoText
                                    response={[options]}
                                    key={index}
                                  ></InfoText>
                                )
                              )}
                            </>
                          )}
                        </>
                      )}

                      {text.type === "scheduleMeeting" && (
                        <>
                          {/* <MeetingCard response={text}/> */}
                          {text?.option?.length > 0 && (
                            <>
                              {text?.option?.map((options: any, index: number) => (
                                  <MeetingCard
                                    response={options}
                                    key={index}></MeetingCard>
                                )
                              )}
                            </>
                          )}
                        </>
                      )}

                      {text.type === 'form' && <JobDetailsForm  onCombinedValues={handleCombinedValues} sessionId={sessionId} />}
                    
                      {text.type === 'email' && <ResumeUpload selectedFile={uploadFile} setSelectedFile={setUploadFile} onSendResume={handleSendResume} />}
                  </div>
                </div>
              )}
            </React.Fragment>
          ))
        }
      </div>

      <StyledModalContainer className={style.formikk}>
        <Formik initialValues={INITIAL_VALUES} 
          validationSchema={VALIDATION_SCHEMA}
          onSubmit={onSubmitHandler} >
          {({
            handleSubmit,
            handleBlur,
            handleChange,
            values
          }) => (
            <Form onSubmit={handleSubmit}>
              <div>
                <TextField
                  id="chat"
                  type="text"
                  name="chat"
                  className={style.textfield}
                  // multiline
                  value={isRecording ? transcript : values.chat}
                  // value={isRecording ? transcript : selectedFile ? selectedFile.name : values.chat}
                  placeholder={
                    fileSizeError || (
                      // (selectedFile ? selectedFile.name : "Type your Message") &&
                    (isInitialState.current ? "Click to begin" : "Type your message"))
                  }
                  required
                  onChange={handleChange}
                  autoComplete="off"
                  onBlur={handleBlur}
                  onKeyDown={(event: React.KeyboardEvent<HTMLDivElement>) => {
                    if (event.key === "Enter") {
                      event.preventDefault();
                      handleSubmit();
                    }
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">

                        {/* emoji */}
                        <IconButton></IconButton>
                        {/* file upload */}
                        {/* <IconButton
                          onClick={() => fileInputRef.current?.click()}
                        >
                          <AttachFileIcon className="pin" />
                          <input
                            ref={fileInputRef}
                            type="file"
                            style={{ display: "none" }}
                            accept=".pdf,.doc,.docx"
                            onChange={(e) => {
                              const file = e.target.files?.[0] as File;
                              if (file) {
                                if (file.size <= 1 * 1024 * 1024) {
                                  setSelectedFile(file);
                                  setFileSizeError(""); // Clear the error message
                                } else {
                                  setSelectedFile(null);
                                  setFileSizeError(
                                    "File should be less than 5MB"
                                  );
                                }
                              }
                            }}
                          />
                        </IconButton> */}

                        {/* mic */}
                        <IconButton
                          onMouseDown={handleStartListening} onMouseUp={handleStopListening} 
                          onTouchStart={handleStartListening} onTouchEnd={handleStopListening} className={style.micIcon} >
                          <Mic />
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                />
              </div>
              <button type="submit" className={style.sendbtn}>
                <img src={iframeData?.btnIcon} alt="SendButton" className={style.sendbtn} />
              </button>
            </Form>
          )}
        </Formik>
      </StyledModalContainer>
      <StyledFooter>
        <div className={style.footertext}>
          Powered by<span className={style.oxylym}>Oxylym</span>
        </div>
      </StyledFooter>

      <audio ref={responsesReceivedRef} id="fadeInSound" controls={false} style={{display:'none'}}>
      <source src="/Sounds/incoming_msg.mp3" type="audio/mp3" />
      </audio>
      <audio ref={formChatRef} id="slideInRightSound" controls={false} style={{display:'none'}}>
        <source src="/Sounds/send.mp3" type="audio/mp3" />
      </audio>
      {/* <audio ref={chipClickRef} id="chipClickSound" controls={false} style={{display:'none'}}>
        <source src="/Sounds/opening_card.mp3" type="audio/mp3" />
      </audio> */}
    </div>
  );
};

export default ChatBody;


